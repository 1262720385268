<template>
  <b-dropdown :text="title" variant="primary">
    <b-dropdown-item
      v-for="item of items"
      :key="item.value"
      :active="active == item.value"
      @click="changed(item)"
      >{{ item.name }}</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
export default {
  name: "BaseToolbarSelect",
  components: {},
  props: {
    // Array of { name: '', value: '', ... }
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    title: {
      type: String,
      default() {
        return "";
      }
    },
    // The value that is active
    active: {
      type: String,
      default: null
    }
  },
  methods: {
    changed: function(item) {
      this.$emit("change", item);
    }
  }
};
</script>

<style></style>
